<template>
  <side-detail-card ref="detail" />
</template>
<script>
import SideDetailCard from "./SideDetailCard.vue";

export default {
  components: { SideDetailCard },

  data: () => ({}),
  computed: {},
  async mounted() {
    this.$refs.detail.initialize(this.$route.params.id, null, null);
  },
};
</script>
